<template>
  <div id='mobile-menu-dropdown' :class="[{ 'opened' : isOpen}, { 'logged-in': isUserLoggedIn }]">
    <div class="action-row" :class="isUserLoggedIn ? 'user-row' : 'login-row'">
      <div v-if="isUserLoggedIn" class="user-text">
        <span class="user-icon">
          <icon-profile :color="'#fff'" />
        </span>
        <span class="name">{{ userName }}</span>
      </div>
      <div v-else class="login-btn" @click="navigateToLoginScreen">
        <span class="user-icon">
          <icon-profile :color="'#fff'" />
        </span>
        <span class="text">{{ $t('navbar.userMenu.loginDropdown.login') }}</span>
      </div>
    </div>
    <div v-if="navigations" class="navs-row">
      <ul>
        <li v-for="(nav, i) in navigations" :key="i">
          <a target="_blank" :href="nav.link" @click="handleNavLinkClick">{{ nav.text }}</a>
        </li>
      </ul>
    </div>
    <div v-if="isUserLoggedIn" class="logged-in-navs-row">
      <ul class="logged-in-navs">
        <li>
          <router-link to="/users/purchases">
            {{ $t('navbar.userMenu.loggedInDropdown.previousOrders') }}
          </router-link>
        </li>
        <li>
          <router-link to="/users/profile">
            {{ $t('navbar.userMenu.loggedInDropdown.profile') }}
          </router-link>
        </li>
      </ul>
      <span class="log-out-btn" @click="handleLogoutBtnClick">
        {{ $t('navbar.userMenu.loggedInDropdown.logout') }}
      </span>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MobileMenuDropdown',
  props: {
    navigations: {
      type: Array,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    'icon-profile': () => import('@/components/shared/elements/icons/IconProfile.vue'),
  },
  data: () => ({
  }),
  created() {},
  computed: {
    ...mapGetters({
      loggedInUserProfile: 'users/getLoggedInUserProfile',
    }),
    isUserLoggedIn() {
      return this.loggedInUserProfile !== undefined;
    },
    userName() {
      return this.isUserLoggedIn ? this.loggedInUserProfile.lastName : undefined;
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.$emit('closeDropdown');
      }
    },
  },
  methods: {
    ...mapActions({
      logout: 'users/logout',
    }),
    navigateToLoginScreen() {
      if (this.$route.name !== 'LoginScreen') {
        this.handleNavLinkClick();
        this.$router.push('/users/login');
      } else {
        this.$emit('closeDropdown');
      }
    },
    handleLogoutBtnClick() {
      this.$emit('closeDropdown');

      setTimeout(() => {
        this.logout().then(() => {
          store.commit('storeCartId', undefined);
          this.$router.go();
        });
      }, 500);
    },
    handleNavLinkClick() {
      this.$emit('closeDropdown');
    },
  },
};
</script>

<style lang='scss' scoped>
#mobile-menu-dropdown {
  background-color: $globalBackgroundColor;
  box-shadow: $lightDropdownShadow;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  max-height: 0;
  overflow: hidden;
  transition: $transitionBaseSlow;

  @media screen and (max-width: $breakpointDownXXs) {
    font-size: 16px;
  }

  &.opened {
    max-height: 1000px;
  }

  &.opened.logged-in {
    padding-bottom: 30px;
  }
}

a,
a:visited {
  text-decoration: none;
  color: $black;
  display: inline-block;
  width: 100%;

  &:hover {
    color: $primaryBlue;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    text-align: center;
    margin: 15px 0;
  }
}

.action-row {
  color: $white;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-row {
  background-color: $primaryOrange;
}

.login-row {
  background-color: $primaryBlue;
}

.user-icon {
  width: 39px;
  height: 39px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  margin: 0 10px 0 0;

  &::v-deep {
    svg {
      width: 26px;
      height: 31px;
    }
  }
}

.user-text {
  display: flex;
  align-items: center;
  justify-content: center;

  .name {
    padding-top: 5px;
  }
}

.login-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.navs-row {
  padding: 15px 0;
}

.logged-in-navs {
  border-top: 3px solid $mobileMenuBorderColor;
  border-bottom: 3px solid $mobileMenuBorderColor;
  margin: 0 70px;
  padding: 15px 0;

  @media screen and (max-width: $breakpointDownXXs) {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.log-out-btn {
  color: $primaryOrange;
  text-align: center;
  cursor: pointer;
  display: block;
  margin: 30px 70px 0;

  @media screen and (max-width: $breakpointDownXXs) {
    margin-left: 30px;
    margin-right: 30px;
  }
}
</style>
